@import 'assets/scss/variables.scss';


.step-nav {
    align-items: center;
    
    .step-nav-back {
        transition: .3s all;
        width: 30px;
        height: 30px;
        padding: 30px;
        border-radius: 60px;
        border: 3px solid transparent;
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        &:hover {
            border-color: $gray-disabled;
        }
    }

    .btn-action {
        transition: .3s all;
        border-radius: 31px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 18px 24px;
        outline: none;

        span.action {
            font-weight: 500;
            letter-spacing: .5px;
            font-size: 1rem;
        }
        img {
            width: 25px;
            height: 25px;
            margin: 0 0 0 auto;
        }

        svg {
            margin: 0 0 0 auto;
            path {
                stroke: white;
            }
        }

        &:disabled {
            opacity: .32;
            background-color: white;
            border: 1px solid $gray;
            color: $gray;
            svg {
                path {
                    stroke: $gray;
                }
            }
        }
    }
}
