@import 'assets/scss/variables.scss';

.pdf-with-arrows {
    .btn-clear { 
        padding: 1.25rem;
        &:active, &:focus {
            box-shadow: none !important;
        }
    }
}

.pdf-viewer-wrap {
    &.upload-preview {
    }
}