@import 'assets/scss/variables.scss';

.month-wrap {
    width: 100%;
    .month-label {
        text-align: center;
        margin: 0 0 1rem;
        background: $red2;
        color: white;

        label {
            font-weight: 700;
            font-size: 26px;
            text-transform: uppercase;
            padding: 1rem;
        }
    }
    .weekdays {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-content: center;
        width: 90%;
        margin: 3rem auto 1rem;

        .day-label {
            font-weight: bold;
            font-size: 16px;
        }
    }
    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-content: center;
        justify-items: center;
        width: 90%;
        margin: 1rem auto 3rem;

        button {
            color: black;
            font-weight: 300;
            font-size: 20px;
            background: transparent;
            border: none;
            padding: 15px;
            
            span{
                display: flex;
                width: 65px;
                height: 65px;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                transition: .3s all;
                &.selected {
                    border: 1px solid $gray-border;
                    border-top: 19px solid $red2;
                    color: $gray-dark;
                    font-weight: 700;
                    box-shadow: $box-shadow;
                    font-size:23px;
                }
            }

            &:hover {
                span {
                    background: lighten($red2, 20%);
                    color: $red2;
                }
            }

            &:disabled {
                color: $gray-disabled;
            }
        }
    }
}