@import 'assets/scss/variables.scss';

.card-button {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    border: 2px solid $gray-border;
    border-radius: $border-radius-base;
    align-items: center;
    justify-content: center;
    outline: none !important;
    padding: 7rem 1.25rem;
    margin-bottom: 1rem;
    min-height: 450px;

    .card-body {
        flex: 1 1 auto;
        padding: 0;
        pointer-events: none;
        h4 {}
        p {
            font-size: 20px;
            font-weight: 200;
            letter-spacing: .03rem;
        }
        img {
            height: 90px;
            width: auto;
            margin-bottom: 1.25rem;
            &.fcm-icon {
                max-height: 60px;
                margin-top: 30px;
            }
        }
    }

    &.active {
        border-color: $blue;
        box-shadow: $box-shadow;
    }

    &.horizontal {
        padding: 1.25rem 2rem !important;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        min-height: auto !important;
    }

    &.nb {
        border: none;
    }
}

.two-col {
    .card-button {
        border-radius: 6px;
        padding: 7rem 1.25rem 3.5rem;
        h4 {
            font-size: 46px !important
        }
        p {
            font-size: 26px;
            font-weight: 200;
            color: $gray;
        }
        img {
            height: 120px;

        }
    }
}

.modal-toggle {
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 5px;
}

.disabled {
  filter: grayscale(100%);
  opacity: 0.3;
}

.disabled:hover {
  cursor: not-allowed;
}
