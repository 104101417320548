@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

//Global Styles
body {
  background: $body-bg;
  color: $gray-dark;
  overflow-x: hidden;
}

h1,h2,h3,h4 {
  font-weight: 500;
  small {
    font-weight: 200;
    color: $gray;
    font-size: 26px;
    letter-spacing: 0;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plus-home {
    width: 16px;
    height: 16px;
    margin: 2px 6px 0px 6px;
  }
}

h1, .h1 { font-size: 3rem; letter-spacing: 0.016em;}

.my-6 {margin-top: 5rem; margin-bottom: 5rem}
.mx-6 {margin-left: 5rem; margin-right: 5rem}
.mt-6 {margin-top: 5rem}
.mb-6 {margin-bottom: 5rem}

.black {color: black} .yellow {color: $yellow} .orange {color: $orange} .red {color: $red} .purple {color: $purple} .blue {color: $blue} .green {color: $green} .muted {color: $gray-border}
.btn-clear {
  background: transparent;
  border: none;
  transition: .3s all;
}
.form-control {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-size: 20px;
  font-weight: lighter;
  color: $gray;
  letter-spacing: 1px;
  padding: 1.5rem 0;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.full-width { width: 100%; }

select.form-control {height: 49px;}

.semibold {font-weight: 500;}

.font-sm {font-size: 0.875rem;}

.text-sm { font-size: 14px; }
.text-md { font-size: 16px; }

::placeholder {
  color: $input-color-placeholder !important;
}

*:focus {
  outline: none !important;
}

.app-container > main {
  width: 100%;
  height: 100%;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.full-center {
  height: calc(100vh - #{$header-height});
  display: flex;
  align-items: center;
  justify-content: center;
}

.nb {
  border: none !important;
  &:focus {
    box-shadow: none !important;
  }
}

.opacity-0 { opacity: 0 !important}

.pointer {
  cursor: pointer;
}

.btn-action-home {
  border-radius: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 24px;
  outline: none;
  transition: .3s all;

  span.action {
      font-weight: 500;
      letter-spacing: .5px;
      font-size: 1rem;
  }
  img {
      width: 25px;
      height: 25px;
      margin: 0 0 0 auto;
  }

  &:disabled {
      opacity: .32;
      background-color: white;
      border: 1px solid $gray;
      color: $gray;
  }
}

.upload-button-wrap {
  
  .upload-button {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    align-items: center;
    justify-content: center;
    outline: none !important;
    padding: 3.5rem 1.25rem;
    margin-bottom: 1rem;
    cursor: pointer;
    word-break: break-all;
    transition: .3s all;

    input:focus {
      outline: none !important;
    }
    .card-body {
      flex: 1 1 auto;
      padding: 0;
      pointer-events: none;

      p { 
        color: $gray; 
        font-size: 26px;
        font-weight: 200;

        &.file-info {
          font-weight: bold;
          margin-bottom: 0;
        }
        u {
          color: $blue;
          letter-spacing: .03rem;
        }
      }
      img {
          height: 140px;
          width: auto;
          margin-bottom: 1.25rem;
          transition: .3s all;
      }
  }
  }
}

.calendar-card {
  width: 100%;
  border: 2px solid $gray-border;
  border-radius: 10px;
  overflow: hidden;
  .calendar-wrap {
    position: relative;
    padding: 0;
    width: 100%;
    .month-toggle {
      position: absolute;
      top: 28px;
      background: transparent;
      border: none;
      transition: .3s all;
      &:focus {
        outline: none !important;
      }
      &:hover {
        opacity: .75;
      }
      &.back {
        left: 1.25rem;
      }
      &.forward {
        right: 1.25rem;
      }
    }
  }
}


.send-confirmation {
  .card-body {
    font-size: 1.25rem;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    margin: 0 0 1.25rem;
    padding: 1.75rem 0;
    .btn-edit {
      position: absolute;
      right: 0;
      top: 0;
      &:hover {
        background: transparent;
        opacity: .5;
      }
    }
    .label {
      font-weight: lighter;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label { 
        font-size: 12px; 
        margin-bottom: 0;
        font-weight: 100;
        margin-top: 1rem;
        color: $gray-dark;
      }
      img {
        height: 26px;
        width: auto;
        max-height: 40px;
      }
    }
    .details {
      font-weight: 200;
      font-size: 20px;
      color: $gray-dark;
      justify-content: flex-start;
      display: flex;
      align-items: center;
    }
    &.how  { border-left: 9px solid $yellow }
    &.who  { border-left: 9px solid $orange }
    &.where  { border-left: 9px solid $red }
    &.what  { border-left: 9px solid $purple }
    &.when  { border-left: 9px solid $blue }
    &.send  { border-left: 9px solid $green }
  }

}

.calendar-day {
  border: 1px solid #EAEAEA;
  padding: 0;
  text-align: center;
  border-radius: 4px;
  width: 45px;
  position: relative;
  h4 {
    margin: 0;
    font-weight: 800;
    font-size: 16px;
  }
  small {
    font-size: 10px;
    font-weight: 700;
    color: white;
    background: #FF243C;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 10px;
    display: block;
  }
}

.row-header {
  .row {padding: 0}
  .row > div {
    text-align: left;
    small {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}


.btn-row {
  width: 100%;
  background: transparent;
  border: none;
  display:flex;
  align-items: center;
  justify-content: center;
  transition: .3s all;
  border-radius: $border-radius-base;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: .03rem;
  cursor: pointer;
  // border-top: 1px solid $gray-border;
  padding: 1rem 0;

  &:hover {
    background: #F7F7F7;
  }

  &.active {
    .check-container { opacity: 1;}
  }
  .type {
    max-width: 40px;
    height: auto;
  }
  .status-btn {
    border-radius: 22px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .75rem;
    padding-left: 0;
    padding-right: 0;
    &:hover {
      background: transparent;
      color: $blue;
    }
  }

}


.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 60px;
  height: 31px;
  font-size: 15px;
  padding: 0;
  border-radius: 16px;
  background-color: $gray-light;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: white;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $gray;
}

.react-toggle--checked .react-toggle-track {
  background-color: $blue;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $blue-light;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 8px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 5px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 8px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 18px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: $gray-light;
  box-shadow: 0px 0px 0px 3px #909090;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 33px;
  background-color: #FAFAFA;
  box-shadow: none;
}


.check-container, .check-parent {
  position: absolute;
  left: -3rem;
  top: calc(50% - 8px);
}



.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.check-row {
  .check-container {
    opacity: 0;
    transition: .5s all;
    width: 65px;
    height: 65px;
  }
  &:hover, &.active {
    .check-container {
      opacity: 1;
    }
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.state-select {
  .css-2b097c-container{width: 100%}
}

.pagination {
  display: inline-block;
}

.sm1em {
  font-size: 1rem;
  color: $gray;
  margin-top: 0.5rem;
}