.big-modal {
  background: white;
  padding: 5rem;
  border-radius: 5px;
  cursor: auto;
}

.big-modal-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 1000;
  cursor: pointer;
}
