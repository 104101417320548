@import 'assets/scss/variables.scss';

#letter-details-modal {
    overflow-y: auto;
    color: white;
    background: rgba(49,49,49, .93);
    .overlay {
        width: 120%;
        height: 120%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background: rgba(49,49,49,.72);
        filter: blur(22px);
    }

    .full-width {
        min-height: 100vh;
        padding: 50px 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .sidebar {
        p {
            color: #ACAEAE;
            font-size: 17px;
            line-height: 27px;
            font-weight: 100;
        }
        .info {
           margin-bottom: 3rem;
           .bullet {
               color: $blue; margin: 0 1rem;
           }
        }
    }
    .content {
        color: #ACAEAE;
        .print-menu {
            top: -41px;
            position: absolute;
            width: 78%;
            left: 9%;
            justify-content: flex-end;
            align-items: center;
            .btn-link {
                padding: .35rem;
                transition: .3s all;
                &:hover {
                    opacity: .5;
                }
            }
        }
    }
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-weight: 100;
        color: #ACAEAE;
        font-size: 33px;
        text-shadow: none;
        opacity: 1;
        cursor: pointer;
    }
    .go-back {
        font-size: 14px;
        font-weight: 500;
        a { color: $blue; text-decoration: underline; cursor: pointer;}
    }
}
