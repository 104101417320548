//Variables
$body-bg: #FFF;
$border-radius-base: 4px;
$border-radius-lg: 4px;
$btn-border-radius-base: $border-radius-base;


$input-color-placeholder: #AFAFAF;
//$blue: #4383ED;
$blue: #4099FE;
$blue-light: #97BAF5;
//$yellow: #F2BF27;
$yellow: #FFC961;
//$orange: #F07825;
$orange: #FF5A07;
$darkred: #C1322D;
//$red: #FF4081;
$red: #FF243C;
$red2: #FF243C;
//$purple: #9462EA;
$purple: #662DD3;
//$green: #0FA538;
$green: #2ED283;

$gray-border: #E0E0E0;
$gray-light: #F0F0F0;
$gray: #A7A7A7;
$gray-disabled: #CDCDD2;
$gray-dark: #333333;
$rainbow: linear-gradient(90deg, rgba(255,182,42,1) 0%, rgba(255,69,0,1) 16%, rgba(255,0,108,1) 36%, rgba(102,45,211,1) 64%, rgba(64,153,254,1) 84%, rgba(46,210,131,1) 100%);
$rainbow45: linear-gradient(45deg, #F5B90A 0%, #EC473A 34%, #4383ED 69%, #0FA538 100%);

$brand-primary: $blue;

$box-shadow: 0px 3px 10px rgba(167,167,167,.29);
$header-height: 80px;

//Responsive Utilities
$screen-sm-min: 576px; // Small tablets and large smartphones
$screen-md-min: 768px; // Small tablets
$screen-lg-min: 992px; // Tablets / small desktops
$screen-xl-min: 1200px; // Large desktops

//Media Query Mixins
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
    @content;
  }
}
