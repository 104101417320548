@import 'assets/scss/variables.scss';

.progress-nav-wrap {
    background: white;
    padding-top: 1rem;


    .progress-nav {
        .tab {
            padding: 2rem 0rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            transition: .3s all;

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
            }

            div {
                display: flex;
                flex-direction: column;
                background: white;
                padding: 0 1.5rem;
                position: relative;
                z-index: 2;

                span {
                    transition: .3s all;
                    margin-top: .5rem;
                    font-weight: lighter;
                }
            }

            &.last {
                &:after {
                    content: '';
                    width: 30%;
                    left: 0;
                    height: 1px;
                    position: absolute;
                }
            }

            &.first {
                &:after {
                    content: '';
                    width: 30%;
                    right: 0;
                    height: 1px;
                    position: absolute;
                }
            }

            &.active, &:hover {
                opacity: .7;
            }
            &.disabled {
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                opacity: .5;
            }

            
        }
    }

    .progress-bar {
        background: $rainbow;
        height: 6px;
        margin: 0 10px;
        position: relative;
        transition: .3s all;
        border-radius: 17px;
        &:after {
            content: ' ';
            background: white;
            height: 4px;
            width: 99%;
            border-radius: 0 17px 17px 0px;
            right: 1px;
            position: absolute;
        }
        &.how:after {width: 89% !important;}
        &.who:after {width: 69% !important;}
        &.where:after {width: 49% !important;}
        &.what:after {width: 30.5% !important;}
        &.when:after {width: 11.5% !important;}
        &.send:after {width: 0% !important;}
    }

}