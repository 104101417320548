@import 'assets/scss/variables.scss';

#top-bar {
  background-color: white;
  border-bottom: 1px solid #f5f5f6;
  height: $header-height;
  z-index: 3;
  padding: 1rem 1.5rem;
}

.navbar-brand {
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
}
.send-title {
  color: $gray;
  font-weight: lighter;
}

button.nav-action {
  padding: 0;
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    box-shadow: none !important;
  }

  .plus-ico {
    width: 14px;
    height: 15px;
  }
}

.btn-user-menu {
  .name {
    font-size: 19px;
    font-weight: 100;
  }
}

.popover-nav {
  display: inline-block;
  position: relative;

  &.active .popover-menu {
    transform: scale(1);
    display: block;
    opacity: 1;
  }
}

.popover-menu {
  border-radius: 4px;
  background: $gray-light;
  list-style-type: none;
  margin: 20px 0;
  min-width: 133px;
  padding: 0.5rem 0;
  position: absolute;
  top: 100%;
  right: 0;

  transition-property: transform, opacity;
  transition-duration: 200ms;
  transition-timing-function: ease;

  transform: scale(0.9);
  transform-origin: top left;
  display: block;
  opacity: 0;

  &:after {
    bottom: 100%;
    left: 72%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $gray-light;
    border-width: 7px;
  }
}

.popover-menu-item {
  white-space: nowrap;
  button {
    border: 0;
    border-radius: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    color: #313131;
    padding: 0.5rem 1rem;
    transition: 0.3s all;
    font-size: 13px;
    &:hover,
    &:focus {
      background-color: #313131;
      color: $gray-light;
    }
  }
}
